// import { URL } from "@/Redux/common/url";
import React, { useEffect, useRef, useState } from "react";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
import ReactToPrint from "react-to-print";
import { Button, Modal } from "react-bootstrap";
// import { allapiAction } from "@/Redux/common/action";
import { useDispatch } from "react-redux";
import moment from "moment";
import { URL } from "../../Redux/common/url";
import { useParams } from "react-router-dom";
import { toPng } from "html-to-image";
import axios from "axios";

function MessageDetailsComponent() {
  const [post, setpost] = useState({});
  const divRef = useRef(null);
  console.log(post);

  const handleDownloadImage = () => {
    if (divRef.current === null) {
      return;
    }

    toPng(divRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        console.log(link);
        link.download = "my-image.png";
        console.log(link);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  const handleDownloadAndShare = () => {
    if (divRef.current === null) {
      return;
    }

    toPng(divRef.current, { cacheBust: true })
      .then((dataUrl) => {
        // Create a link to download the image
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "my-image.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        console.log("dsfgsdhf");
        // Generate a shareable link with the website URL
        const websiteUrl = encodeURIComponent(window.location.href);
        console.log("dsfgsdhf-----------1");
        const message = `Check out this image I created! You can view it here: ${window.location.href}`;
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
        console.log("dsfgsdhf-----------2");
        console.log("dsfgsdhf-----------2", whatsappUrl);
        // Open WhatsApp to share the URL
        window.open(whatsappUrl, "_blank");
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  const handleShareOnWhatsApp = () => {
    if (divRef.current === null) {
      return;
    }

    toPng(divRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const blob = dataURLtoBlob(dataUrl);
        const file = new File([blob], "my-image.png", { type: "image/png" });
        const formData = new FormData();
        formData.append("file", file);

        const whatsappUrl = `https://api.whatsapp.com/send?text=Here is the image I created`;
        window.open(whatsappUrl, "_blank");
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  const dataURLtoBlob = (dataURL) => {
    const parts = dataURL.split(",");
    const mime = parts[0].match(/:(.*?);/)[1];
    const bstr = atob(parts[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  // const divRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");

  const handleGenerateImage = () => {
    if (divRef.current === null) {
      return;
    }

    toPng(divRef.current, { cacheBust: true })
      .then((dataUrl) => {
        uploadImage(dataUrl);
      })
      .catch((err) => {
        console.error("Oops, something went wrong!", err);
      });
  };

  const uploadImage = async (dataUrl) => {
    const formData = new FormData();
    formData.append("file", dataUrl);
    formData.append("upload_preset", "your_upload_preset"); // आपके क्लाउडिनरी अपलोड प्रीसेट
    formData.append("cloud_name", "your_cloud_name"); // आपके क्लाउडिनरी क्लाउड नाम

    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/your_cloud_name/image/upload",
        formData
      );
      setImageUrl(response.data.secure_url);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleShareOnWhatsApp2 = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(imageUrl)}`;
    window.open(whatsappUrl, "_blank");
  };

  const { id } = useParams();
  async function getServerSideProps(id) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    console.log(id);
    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      URL.API_BASE_URL + `${URL.messageDetails}`,
      requestOptions
    );

    // const res2 = await fetch(
    //   URL.API_BASE_URL + `${URL.lostfoundlist}`,
    //   requestOptions
    // );
    const events = await res.json();
    // const events2 = await res2.json();

    console.log(events);

    const postData = events?.data;
    setpost(postData);
    // const postData2 = events2?.data;

    if (!events) {
      return { notFound: true };
    }

    return {
      props: {
        post: postData,
        // postList: postData2,
        // postList: postDataList,
      },
    };
  }
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [showDelelt, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  useEffect(() => {
    getServerSideProps(id);
    // dispatch(allapiAction.getsliderList({ page: "winner" }));
  }, []);
  return (
    <div className="hometest">
      <Modal show={showDelelt} size="lg" onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title> Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container img100 " ref={componentRef}>
            <div className="row">
              <div className="col-12">
                <div className="card text-center certificate mt-25">
                  <img
                    src="/img/certificate.png"
                    className="certificate_image"
                    alt="..."
                  />
                  <div className="card-img-overlay text-center">
                    <div className="cer">
                      <h1 className="card-title cer"></h1>
                      <img src="/img/cer.png" alt="cer" />
                      <h6 className="card-text second_txt"></h6>
                    </div>
                    <p className="card-text mt-5 ">
                      THIS CERTIFICATE IS PROUDLY PRESENTED TO
                    </p>
                    <h3 className=" name text-danger">{post?.name}</h3>
                    {/* <h5 className="mt-5">
                      <strong>Lorem Ipsum dolor sit amet adipiscing </strong>
                    </h5> */}
                    <p className="achiver_pra">
                      “This certificate is awarded to{" "}
                      <b className="text-capitalize">{post?.name}</b> in
                      recognition of their remarkable achievements and
                      exceptional contributions to{" "}
                      <b className="text-capitalize">{post?.crType}</b> on
                      {moment(post?.createdAt).format(" DD/MM/YYYY ")}. Your
                      hard work, determination, and passion have set a high
                      standard of excellence”
                      <br />
                    </p>

                    <div className="d-flex justify-content-around mt-5 pt-5">
                      {/* <p className="sign">
                        <i>Signature</i>
                      </p> */}
                      {/* <p className="sign">
                        <i>Signature</i>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <ReactToPrint
            trigger={() => (
              <Button variant="primary">Print / Download</Button>
              // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
              //   Print / Download
              // </button>
            )}
            content={() => componentRef.current}
          /> */}

          {/* <Button variant="secondary" onClick={handleCloseDelete}>
              No
            </Button>
            <Button variant="primary" onClick={hendleDelete}>
              Yes
            </Button> */}
        </Modal.Footer>
      </Modal>

      <div className="marquee">
        <marquee behavior="scroll" direction="left" scrollamount="05">
          Slow moto Scrolling
        </marquee>
      </div>
      {/* <HomeSlider /> */}

      <div className="container-fluid">
        {/* <h2 className="text-center Achievers">Our Achievers</h2>
        <div className="row">
          <div className="col-md-3">
            <div className="card-body">
              <img
                src={URL.API_BASE_URL + post?.image}
                alt=""
                className="cer_img"
              />
            </div>
          </div>
          <div className="col-md-6">
            <h3>Achievers {post?.name}</h3>
            <p className="abc">
              Archives have several characteristics: They are only retained if
              they are considered to be of long-term historical value. This can
              be difficult to assess but what it means is that archive
              collections do not and cannot hold every document ever created.
              They are not created consciously as a historical record. Archives
              have several characteristics: They are only retained if they are
              considered to be of long-term historical value.
            </p>
          </div>
          <div className="col-md-3">
            <img
              src="/img/png-clipart-trophy-award-trophy-data-award-removebg-preview.png"
              alt=""
              className="troffy img-fluid"
            />
          </div>
        </div> */}
      </div>

      {/* certifi */}

      {post?.categoryId == "weddingWishes" && (
        <div class="container-fluid weddingbg">
          <div class="row">
            <div class="col-12">
              <div class="row m-1">
                <div class="col-lg-6 " data-aos="fade-right">
                  <div class="row m-">
                    <div class=" border pho">
                      <div class="row">
                        <div class="col-12 text-center">
                          <h1 class="mt-5 pt-5 head">
                            WEDDING <br /> ANNIVERSARY
                          </h1>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-12 text-center mt-2"
                          data-aos="zoom-in-down"
                        >
                          <img
                            src={URL.API_BASE_URL + post?.imagefor}
                            class="img-fluid frame"
                            id="borderimg"
                            width="350"
                            height="250"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 text-center">
                          <h1 class="mt-2 head"> {post?.name}</h1>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8 text-center hhh">
                          <p class="mt-1">{post?.description} </p>
                        </div>
                        <div class="col-2"></div>
                      </div>
                      <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8 text-center">
                          <button
                            type="button"
                            class="btn border btn-md btn-block"
                          >
                            from. :- bestbharat.com
                          </button>
                        </div>
                        <div class="col-2"></div>
                      </div>
                      <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8 text-center">
                          <h3 class="mt-2">{post?.userId?.fullName}</h3>
                        </div>
                        <div class="col-2"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 pho" data-aos="fade-left">
                  <div class="row  m-1">
                    <div class="col-12 border shadow-lg mb-5 bg-white rounded">
                      <h3>
                        Best wishes form :- <b> bestbharat.com </b>
                      </h3>
                      <p>
                        “May your love continue to shine brightly.” “Wishing you
                        a lifetime of love and happiness.” “Congratulations on
                        another year of love and laughter.” “Here's to many more
                        years of togetherness.” “May your love story keep
                        inspiring us all.”
                      </p>
                      <h3 class="mt-2">
                        To, <b> {post?.name}</b>
                      </h3>
                      <img
                        src="https://thesugarrandspice.com/wp-content/uploads/2019/11/2.png"
                        class="img-fluid text-center"
                        data-aos="flip-left"
                      />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {post?.categoryId == "birthdayWishes" && (
        <div class="container bitrthdayBg">
          <div class="row mt-2 d-flex">
            <div class="col-md-6 mb-4 d-flex">
              <div class="card card1 p-3 flex-fill">
                <div class="circle">
                  <img
                    src={URL.API_BASE_URL + post?.imagefor}
                    class="birthday_user"
                  />
                </div>
                <div class="content">
                  <h1>
                    <span class="happy-birthday">
                      Happy Birthday,
                      <br />
                      {post?.name}
                     
                    </span>
                  </h1>
                  <img
                    src="https://i.pinimg.com/originals/9a/fe/92/9afe92b4c0325bbafce89e4983af7841.png"
                    class="cake_image"
                  />
                  <div class="sender">
                    From, {post?.userId?.fullName}
                   
                  </div>
                </div>
                <img
                  src={URL.API_BASE_URL + post?.image}
                  class="sender_image"
                />
                <p class="speech-bubble p-25">{post?.description}</p>

           
                <div class="share-buttons">
                  <button class="btn btn-success">
                    <i class="fab fa-whatsapp"></i>
                  </button>
                  <button class="btn btn-primary">
                    <i class="fab fa-facebook"></i>
                  </button>
                  <button class="btn btn-danger">
                    <i class="fab fa-instagram"></i>
                  </button>
                  <button class="btn btn-dark">
                    <i class="fas fa-copy"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4 d-flex">
              <div class="card p-3 second_card flex-fill">
                <h3 class="animated-text">
                  <span>Best Wishes From BestBharat</span>
                </h3>
                <p class="text-white">
                  Wishing you a very happy birthday! May all your dreams come
                  true and may you have a wonderful year ahead.
                </p>
                <img
                  src="https://images.fineartamerica.com/images/artworkimages/medium/2/happy-birthday-wishes-or-invitation-mickey-movie-poster-prints-transparent.png"
                  class="wish_cartoon"
                />
                <p class="love-message">❤️ With love , BestBharat ❤️</p>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {post?.categoryId == "birthdayWishes" && (
        <div class="container brnew">
          <div class="row mt-2 d-flex">
            <div class="col-md-6 mb-4 d-flex">
              <div class="card card1 p-20 flex-fill" ref={divRef}>
                <div class="circle">
                  <img
                    src={URL.API_BASE_URL + post?.imagefor}
                    class="birthday_user"
                  />
                </div>
                <div class="content">
                  <h1>
                    <span class="happy-birthday">
                      Happy Birthday,
                      <br /> {post?.name}
                    </span>
                  </h1>
                  {/* <!-- <img src="https://i.pinimg.com/originals/9a/fe/92/9afe92b4c0325bbafce89e4983af7841.png"
                            class="cake_image" />
                        <div class="sender">From, [Sender's Name]</div> --> */}
                </div>
                <div class="card sender_card_design">
                  <div class="row">
                    <div class="col-md-3 col-3 d-flex align-items-center justify-content-center p-15">
                      <img
                        src={URL.API_BASE_URL + post?.image}
                        class="sender_image"
                      />
                    </div>
                    <div class="col-md-9 col-8">
                      <div class="sender">
                        From, {post?.userId?.fullName}
                        {/* [Sender's Name] */}
                      </div>
                      <p class="speech-bubble p-4">{post?.description}</p>
                    </div>
                  </div>
                </div>

                <img
                  src="https://static.vecteezy.com/system/resources/previews/025/267/278/non_2x/isolated-gold-balloon-and-celebration-event-ai-generated-free-png.png"
                  class="left_side_bloon"
                />
                <img
                  src="https://static.vecteezy.com/system/resources/previews/025/267/278/non_2x/isolated-gold-balloon-and-celebration-event-ai-generated-free-png.png"
                  class="right_side_bloon"
                />
                <a href="https://www.bestbharat.com/" class="link-text">
                  www.bestbharat.com
                </a>

                {/* <!-- Share buttons --> */}
                <div class="share-buttons">
                  <button
                    class="btn btn-success"
                    onClick={handleDownloadAndShare}
                  >
                    <i class="fab fa-whatsapp"></i>
                  </button>
                  <button class="btn btn-primary">
                    <i class="fab fa-facebook"></i>
                  </button>
                  <button class="btn btn-danger">
                    <i class="fab fa-instagram"></i>
                  </button>
                  <button class="btn btn-dark">
                    <i class="fas fa-copy"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4 d-flex">
              <div class="card p-3 second_card flex-fill">
                <h3 class="animated-text">
                  <span>Best Wishes From BestBharat</span>
                </h3>
                <p class="text-white">
                  Wishing you a very happy birthday! May all your dreams come
                  true and may you have a wonderful year ahead.
                </p>
                <div class="image-container">
                  <img
                    src="https://media0.giphy.com/avatars/Kcomics/1417iwDGJllV.gif"
                    class="wish_cartoon"
                  />
                  <p class="text-center text-white">
                    <b>
                      ❤️ आपको जन्मदिन की बहुत शुभकामनाएं! आपके सभी सपने सच हों
                      और आपका आने वाला साल शानदार हो। ❤️
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <button onClick={handleDownloadAndShare}>Download and Share</button>
          <button onClick={handleDownloadImage}>Download as Image</button>
          <button onClick={handleShareOnWhatsApp}>Share on WhatsApp</button>

          <button onClick={handleGenerateImage}>Generate Image</button>
          {imageUrl && (
            <button onClick={handleShareOnWhatsApp}>Share on WhatsApp</button>
          )} */}
        </div>
      )}

      {/* <div className="container mt-25 mb-25 " ref={componentRef}>
        <div className="row">
          <div className="col-12">
            <div className="card text-center certificate rscertificate">
              {post?.categoryId == "birthdayWishes" ? (
                <img
                  src="/img/Birthday.png"
                  className="certificate_image_b"
                  alt="..."
                />
              ) : post?.categoryId == "condolenceMessage" ? (
                <img
                  src="/img/Shoksandesh.png"
                  className="certificate_image_b"
                  alt="..."
                />
              ) : (
                <img
                  src="/img/Marriedlife.png"
                  className="certificate_image_b"
                  alt="..."
                />
              )}
              <div className="card-img-overlay text-center">
                <div className="d-flex justify-content-around mt-5 pt-5">
                  <div style={{ width: "180px", height: "180px" }}>
                    <img
                      style={{ width: "100%" }}
                      src={URL.API_BASE_URL + post?.imagefor}
                      alt=""
                      className="btimg"
                    />
                  </div>
                </div>

                <h3
                  className={
                    post?.categoryId == "birthdayWishes"
                      ? " name text-danger mt-3"
                      : "name text-danger mt-3 vhmt"
                  }
                >
                  {post?.name}
                </h3>
                {post?.categoryId == "birthdayWishes" && (
                  <p className="achiver_pra">{post?.description}</p>
                )}
              </div>

              <div className="wishby">
                <img src={URL.API_BASE_URL + post?.image} alt="" />{" "}
                <span>{post?.userId?.fullName}</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="d-flex justify-content-around mt-25 mb-25">
        <div className="buttons d-flex">
          {/* <a href="#">
            <button className="btn btn-1">DOWNLOAD</button>
          </a> */}

          {/* <a href="#">
            <button
              className="btn btn-2"
              onClick={() => {
                setShowDelete(true);
              }}
            >
              PRINT
            </button>
          </a> */}
          {/* <ReactToPrint
            trigger={() => (
              <button className="btn btn-2">PRINT</button>
              // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
              //   Print / Download
              // </button>
            )}
            content={() => componentRef.current}
          /> */}
        </div>
      </div>

      <OurWork />
    </div>
  );
}

export default MessageDetailsComponent;
