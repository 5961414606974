import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { URL } from "../../Redux/common/url";

function Cmimg2({ url, altkey }) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div>
      <img
        src={url}
        alt={altkey}
        className={`winner-img blur-image  ${isLoaded ? "hide" : ""}`}
        style={{
          filter: "blur(20px)",
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 0 : 1,
        }}
      />
      <LazyLoadImage
        alt={altkey}
        // width={imageWidth}
        // height={imageHeight}
        className="winner-img"
        delayTime={300}
        onLoad={() => setIsLoaded(true)}
        style={{
          // Hide until loaded
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 1 : 0,
        }}
        src={url} // use normal <img> attributes as props
      />
    </div>
  );
}

export default Cmimg2;
