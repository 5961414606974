/* import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor"; */
// import Api from "@/Redux/common/api";
// import { URL } from "@/Redux/common/url";

// import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import Api from "../../../../Redux/common/api";
import { URL } from "../../../../Redux/common/url";
import Cktest from "../../../cktetst/Cktest";
import { AsyncPaginate } from "react-select-async-paginate";

export default function AddJobComponent() {
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  // const allStates = State.getAllStates();
  const [formData, setFormData] = useState({});
  const [interviewStartDate, setInterviewStartDate] = useState(null);
  const [interviewLastDate, setInterviewLastDate] = useState(null);
  const [isButtonEnable, setButtonStatus] = useState(true);
  const navigate = useNavigate();
  const jobCategory = [
    "Accountant",
    "Accounts, Finance & Financial Services",
    "Admin",
    "Admin Operation",
    "Administration",
    "Administration Clerical",
    "Advertising",
    "Advertising",
    "Advertisment",
    "Architects & Construction",
    "Architecture",
    "Bank Operation",
    "Business Development",
    "Business Management",
    "Business Systems Analyst",
    "Clerical",
    "Client Services & Customer Support",
    "Computer Hardware",
    "Computer Networking",
    "Consultant",
    "Content Writer",
    "Corporate Affairs",
    "Creative Design",
    "Creative Writer",
    "Customer Support",
    "Data Entry",
    "Data Entry Operator",
    "Database Administration (DBA)",
    "Development",
    "Distribution & Logistics",
    "Education & Training",
    "Electronics Technician",
    "Engineering",
    "Engineering Construction",
    "Executive Management",
    "Executive Secretary",
    "Field Operations",
    "Front Desk Clerk",
    "Front Desk Officer",
    "Graphic Design",
    "Hardware",
    "Health & Medicine",
    "Health & Safety",
    "Health Care",
    "Health Related",
    "Hotel Management",
    "Hotel/Restaurant Management",
    "HR",
    "Human Resources",
    "Import & Export",
    "Industrial Production",
    "Installation & Repair",
    "Interior Designers & Architects",
    "Intern",
    "Internship",
    "Investment Operations",
    "IT Security",
    "IT Systems Analyst",
    "Legal & Corporate Affairs",
    "Legal Affairs",
    "Legal Research",
    "Logistics & Warehousing",
    "Maintenance/Repair",
    "Management Consulting",
    "Management Information System (MIS)",
    "Managerial",
    "Manufacturing",
    "Manufacturing & Operations",
    "Marketing",
    "Marketing",
    "Media - Print & Electronic",
    "Media & Advertising",
    "Medical",
    "Medicine",
    "Merchandising",
    "Merchandising & Product Management",
    "Monitoring & Evaluation (M&E)",
    "Network Administration",
    "Network Operation",
    "Online Advertising",
    "Online Marketing",
    "Operations",
    "Planning",
    "Planning & Development",
    "PR",
    "Print Media",
    "Printing",
    "Procurement",
    "Product Developer",
    "Product Development",
    "Product Development",
    "Product Management",
    "Production",
    "Production & Quality Control",
    "Project Management",
    "Project Management Consultant",
    "Public Relations",
    "QA",
    "QC",
    "Qualitative Research",
    "Quality Assurance (QA)",
    "Quality Control",
    "Quality Inspection",
    "Recruiting",
    "Recruitment",
    "Repair & Overhaul",
    "Research & Development (R&D)",
    "Research & Evaluation",
    "Research & Fellowships",
    "Researcher",
    "Restaurant Management",
    "Retail",
    "Retail & Wholesale",
    "Retail Buyer",
    "Retail Buying",
    "Retail Merchandising",
    "Safety & Environment",
    "Sales",
    "Sales & Business Development",
    "Sales Support",
    "Search Engine Optimization (SEO)",
    "Secretarial, Clerical & Front Office",
    "Security",
    "Security & Environment",
    "Security Guard",
    "SEM",
    "SMO",
    "Software & Web Development",
    "Software Engineer",
    "Software Testing",
    "Stores & Warehousing",
    "Supply Chain",
    "Supply Chain Management",
    "Systems Analyst",
    "Teachers/Education, Training & Development",
    "Technical Writer",
    "Tele Sale Representative",
    "Telemarketing",
    "Training & Development",
    "Transportation & Warehousing",
    "TSR",
    "Typing",
    "Warehousing",
    "Web Developer",
    "Web Marketing",
    "Writer",
  ];

  // const statesindia =
  //   allStates &&
  //   allStates?.filter((data) => {
  //     return data.countryCode == "IN";
  //   });

  const [aboutPage, setaboutPage] = useState("");
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  const [stateselect, setstateselect] = useState(null);
  const [districtselect, setdistrictselect] = useState(null);
  const [cityselect, setcityselect] = useState(null);
  const [userseletecate, setuserseletecate] = useState(null);
  const [mangeKey, setmangeKey] = useState(0);
  const [mangeKey2, setmangeKey2] = useState(0);

  useEffect(() => {
    setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setdistrictselect(null);
    setcityselect(null);

    return () => {};
  }, [stateselect]);
  useEffect(() => {
    // setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setcityselect(null);
    return () => {};
  }, [districtselect]);

  const loadOptionsState = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaStates?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsDisrcit = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaDistricts?key=${search}&page=${pages?.page}&States_id=${stateselect?.State}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const loadOptionsCity = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacity?key=${search}&page=${pages?.page}&District_id=${districtselect?.District}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.Area + ", " + data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const loadOptionscategory = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);

    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacategorysearch?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  useEffect(() => {
    // if (selectedState) {
    //   setCities(City.getCitiesOfState("IN", selectedState));
    // } else {
    //   setCities([]);
    // }
  }, [selectedState]);

  /* function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  } */

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addJob = async (e) => {
    e.preventDefault();
    setButtonStatus(false);
    formData["interview_start_date"] = interviewStartDate
      ? interviewStartDate.format("YYYY-MM-DD")
      : "";
    formData["interview_last_date"] = interviewLastDate;

    console.log(formData);
    const token = localStorage.getItem("access_token_vendor");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    if (!formData.company_name) {
      toast.error("Please enter company name!!");
    } else if (!stateselect) {
      toast.error("Please select state!!");
    } else if (!districtselect) {
      toast.error("Please select district!!");
    } else if (!formData.job_title) {
      toast.error("Please job title!!");
    } else if (!cityselect) {
      toast.error("Please enter city!!");
    } else if (!formData.address) {
      toast.error("Please enter address!!");
    } else if (!aboutPage) {
      toast.error("Please enter job discription!!");
    } else {
      formData["job_discription"] = aboutPage;
      formData["state"] = stateselect?.State;
      formData["district"] = districtselect?.District;
      formData["city"] = cityselect?.Area;
      formData["categoryId"] = userseletecate?._id;
      formData["job_category"] = userseletecate?.name;
      // job_skill,
      // ,
      // district: districtselect?.District,
      // city: cityselect?.Area,
      // state: stateselect?.State,

      const response = await Api.post(`${URL.addJob}`, formData, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        navigate("/seller/joblist");
        setaboutPage("");
      } else {
        toast.error(response?.data?.message);
      }
    }
    setButtonStatus(true);
  };

  return (
    <Container>
      <h4 className="mt-5">Add Job</h4>
      <Form onSubmit={addJob}>
        <Row>
          <Col md={6} sm={6}>
            <Form.Group controlId="company_name" className="mb-20">
              <Form.Label>Your Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company_name"
                // value={formData.name}
                onChange={handleChange}
                placeholder="Enter your company name"
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group controlId="job_title" className="mb-20">
              <Form.Label>Job Title (Job Name)</Form.Label>
              <Form.Control
                type="text"
                name="job_title"
                // value={formData.text}
                onChange={handleChange}
                placeholder="Enter job title"
              />
            </Form.Group>
          </Col>
          {/* <Col md={12}>
            <Form.Group controlId="job_detail" className="mb-20">
              <Form.Label>Job Detail</Form.Label>
              <CKEditor
                editor={Editor}
                data={''}
                // onChange={(event, editor) => {
                //   const data = editor.getData();
                //   setaboutPage(data);
                // }}
                config={{
                  extraPlugins: [uploadPlugin],
                }}
              />
            </Form.Group>
          </Col> */}
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Job Category</Form.Label>
              <AsyncPaginate
                value={userseletecate}
                className="liceninputv2"
                // placeholder="location"
                placeholder="Category"
                loadOptions={loadOptionscategory}
                onChange={setuserseletecate}
                additional={{
                  page: 1,
                }}
              />
              {/* <Form.Select
                name="job_category"
                size="lg"
                className="form-control"
                onChange={handleChange}
              >
                <option value={``}>Select Category</option>
                {jobCategory &&
                  jobCategory.map((category, i) => {
                    return <option key={i}>{category}</option>;
                  })}
              </Form.Select> */}
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Job Types</Form.Label>
              <Form.Select
                name="job_type"
                size="lg"
                className="form-control"
                onChange={handleChange}
              >
                <option value={``}>Select Type</option>
                <option value={"Contract"}>Contract</option>
                <option value={"Freelance"}>Freelance</option>
                <option value={"Full Time/Permanent"}>
                  Full Time/Permanent
                </option>
                <option value={"Internship"}>Internship</option>
                <option value={"Part Time"}>Part Time</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <div className=" col-sm-6 col-12">
            <div class="input-box2">
              <span class="icon">
                <ion-icon name="mail"></ion-icon>
              </span>
              <label>State</label>
              {/* <input
                type="text"
                required
                name="state"
                value={userData?.state}
                onChange={(e) => {
                  hendletochange(e);
                }}
              /> */}

              <AsyncPaginate
                value={stateselect}
                className="liceninput"
                placeholder="State"
                loadOptions={loadOptionsState}
                onChange={setstateselect}
                additional={{
                  page: 1,
                }}
              />

              {/* <select
                id=""
                className=""
                onChange={(e) => {
                  setState(e?.target?.value);
                }}
              >
                <option value={""}>Select State</option>

                {statesindia &&
                  statesindia?.map((data, i) => {
                    return (
                      <option key={i} value={data?.name}>
                        {data?.name}
                      </option>
                    );
                  })}
              </select> */}
            </div>
          </div>
          <div className=" col-sm-6 col-12">
            <div class="input-box2">
              <label>District</label>
              <AsyncPaginate
                key={mangeKey}
                value={districtselect}
                className="liceninput"
                placeholder="District"
                loadOptions={loadOptionsDisrcit}
                onChange={setdistrictselect}
                additional={{
                  page: 1,
                }}
              />
              {/* <span class="icon">
                <ion-icon name="mail"></ion-icon>
              </span>
              <input
                type="text"
                required
                name="district"
                value={userData?.district}
                onChange={(e) => {
                  hendletochange(e);
                }}
              /> */}
            </div>
          </div>
          <div className=" col-sm-6 col-12">
            <div class="input-box2">
              <span class="icon">
                <ion-icon name="mail"></ion-icon>
              </span>
              {/* <input
                type="text"
                required
                name="city"
                value={userData?.city}
                onChange={(e) => {
                  hendletochange(e);
                }}
              /> */}
              <label>City</label>
              <AsyncPaginate
                key={mangeKey2}
                value={cityselect}
                className="liceninput"
                placeholder="City"
                loadOptions={loadOptionsCity}
                onChange={setcityselect}
                additional={{
                  page: 1,
                }}
              />
            </div>
          </div>
          {/* <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>State</Form.Label>
              <Form.Select
                name="state"
                size="sm"
                className="form-control"
                onChange={(e) => {
                  setSelectedState(JSON.parse(e.target.value).isoCode);
                  handleChange(e);
                }}
              >
                <option>Select State</option>
                {statesindia &&
                  statesindia.map((state, i) => {
                    return (
                      <option key={i} value={JSON.stringify(state)}>
                        {state.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>District</Form.Label>
              <Form.Select
                name="district"
                size="sm"
                className="form-control"
                onChange={handleChange}
              >
                <option>Select District</option>
                {cities &&
                  cities.map((city, i) => {
                    return (
                      <option key={i} value={city.name}>
                        {city.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="Enter city"
                onChange={handleChange}
              />
            </Form.Group>
          </Col> */}
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Enter your address"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Salary From</Form.Label>
              <Form.Control
                type="text"
                name="salary_from"
                onChange={handleChange}
                placeholder="Salary from"
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Salary To</Form.Label>
              <Form.Control
                type="text"
                name="salary_to"
                onChange={handleChange}
                placeholder="Salary to"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-20">
              <Form.Label>Payment Period-Salary Period</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="salary_period"
                size="lg"
                className="form-control"
              >
                <option value={``}>Select period</option>
                <option value={"Monthly"}>Monthly</option>
                <option value={"Weekly"}>Weekly</option>
                <option value={"Yearly"}>Yearly</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Job Shift</Form.Label>
              <Form.Select
                onChange={handleChange}
                name="job_shift"
                size="lg"
                className="form-control"
              >
                <option value={``}>Select shift</option>
                <option>First Shift (Day)</option>
                <option>Second Shift (Afternoon)</option>
                <option>Third Shift (Night)</option>
                <option>Rotating</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group>
              <Form.Label>Interview Start Date</Form.Label>
              <DatePicker
                size="large"
                placeholder="Start date"
                onChange={(date) => setInterviewStartDate(date)}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Interview Last Date</Form.Label>
              <DatePicker
                size="large"
                placeholder="Start date"
                onChange={(date) => setInterviewLastDate(date)}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Gender</Form.Label>
              <Form.Select
                name="gender"
                onChange={handleChange}
                className="form-control"
                size="lg"
              >
                <option value={""}>Select gender</option>
                <option value={"Male"}>Male</option>
                <option value={"Female"}>Female</option>
                <option value={"both "}>Both </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Required Degree Level | Minimum Education</Form.Label>
              <Form.Select
                name="degree_level"
                onChange={handleChange}
                className="form-control"
                size="lg"
              >
                <option value={``}>Select degree level</option>
                <option value={"10th or below 10th"}>10th or below 10th</option>
                <option value={"12th pass"}>12th pass</option>
                <option value={"Graduate"}>Graduate</option>
                <option value={"Post Graduate"}>Post Graduate</option>
                <option value={"Diploma"}>Diploma</option>
                <option value={"Iti"}>Iti</option>
                {/* <option>Matriculation/O-Level</option>
                <option>Intermediate/A-Level</option>
                <option>Bachelors</option>
                <option>Masters</option>
                <option>MPhil/MS</option>
                <option>PHD/Doctorate</option>
                <option>Certification</option>
                <option>Diploma</option>
                <option>Short Course</option> */}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Language Required</Form.Label>
              <Form.Select
                name="language"
                onChange={handleChange}
                className="form-control"
                size="lg"
              >
                <option value={``}>Select Language Required</option>
                <option value={`Hindi Only`}>Hindi Only</option>
                <option value={`Hindi and English`}>Hindi and English</option>
                <option value={`Good English`}>Good English</option>
                <option value={`Other `}>Other </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Working From</Form.Label>
              <Form.Select
                name="workinglocationtype"
                onChange={handleChange}
                className="form-control"
                size="lg"
              >
                <option value={``}>Select Working From</option>
                <option value={`Office`}>Office</option>
                <option value={`Home`}>Home</option>
                <option value={`Field market`}>Field market</option>
                {/* <option value={`Other `}>Other </option> */}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Working Location Address</Form.Label>
              <Form.Control
                type="text"
                name="workinglocationarea"
                placeholder="Enter your working location address"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Any Skills</Form.Label>
              <Form.Control
                type="text"
                name="anyskills"
                placeholder="Enter your working location address"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6}>
            <Form.Group className="mb-20">
              <Form.Label>Required Job Experience</Form.Label>
              <Form.Select
                name="job_experience"
                onChange={handleChange}
                className="form-control"
                size="lg"
              >
                <option value={``}>Select job experience</option>
                <option>Fresh</option>
                <option>Less Than 1 Year</option>
                <option>1 Year</option>
                <option>2 Year</option>
                <option>3 Year</option>
                <option>4 Year</option>
                <option>5 Year</option>
                <option>6 Year</option>
                <option>7 Year</option>
                <option>8 Year</option>
                <option>9 Year</option>
                <option>10 Year</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Is Active ?</Form.Label>
              <div className="mb-3">
                <Form.Check
                  inline
                  type="radio"
                  name="job_status"
                  label="Active"
                  value={1}
                  id="job_stat_active"
                  className="form-radio"
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  name="job_status"
                  label="In-Active"
                  value={0}
                  id="job_stat_inactive"
                  className="form-radio"
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Hide Salary ?</Form.Label>
              <div className="mb-3">
                <Form.Check
                  inline
                  type="radio"
                  name="hideSalary"
                  label="Yes"
                  value={1}
                  id="job_stat_active"
                  className="form-radio"
                  onChange={handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  name="hideSalary"
                  label="No"
                  value={0}
                  id="job_stat_inactive"
                  className="form-radio"
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Col>

          <Col md={12} sm={12}>
            <label>Job Description</label>
            <Cktest
              aboutPage={aboutPage}
              setaboutPage={setaboutPage}
              uploadPlugin={uploadPlugin}
            />
          </Col>
          <Col md={12}>
            {isButtonEnable && (
              <Button
                className="btn btn-lg mt-5"
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
