import { Col, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import HeadingBack from "../component/HeadingBack";
// import { allapiAction } from "../../Redux/common/action";
// import { URL } from "@/Redux/common/url";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
// import Api from "@/Redux/common/api";
import Select from "react-select";
import SvgIcon from "../../const/SvgIcon";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build/build/ckeditor";
// import dynamic from "next/dynamic";
import Cktest from "../../cktetst/Cktest";
import ImageUploading from "react-images-uploading";
import { DeleteOutlined } from "@ant-design/icons";
// import { useRouter } from "next/router";
import HeadingBack from "../../HeadingBack";
// import { allapiAction } from "@/Redux/common/action";
// import Link from "next/link";
import { Image } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../../Redux/common/url";
import Api from "../../../Redux/common/api";
import { allapiAction } from "../../../Redux/common/action";
import ImageCropper from "../../ImageCroper/ImageCropper";
// import Image from "next/image";

function EditAboutPageComponent() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [aboutPage, setaboutPage] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [subList, setsubList] = useState([]);

  const [productData, setproductData] = useState({});
  const { id } = useParams();
  const get_sub_sub_category_list = useSelector((state) =>
    state.allapi.get_sub_sub_category_list
      ? state.allapi.get_sub_sub_category_list
      : []
  );
  const get_product_details = useSelector((state) =>
    state.allapi.blog_details ? state.allapi.blog_details : {}
  );

  const [imagesproI, setImagesproI] = React.useState([]);

  const [fileName, setFileName] = useState("");
  const [fileContant, setfileContant] = useState("");
  const [finalImg, setfinalImg] = useState("");
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const maxNumberss = 3;

  const onChangeproI = (imageList, addUpdateIndex) => {
    // data for submit

    setImagesproI(imageList);
  };
  useEffect(() => {
    // if (get_product_details?.subcolor?.length > 0) {
    // setImagesfe([{data_url: URL.API_BASE_URL + e.image}])

    // const finalCate = get_product_details?.categoryDetails[0]

    const selectCate = subList.find(
      (edata) => edata?._id == get_product_details?.categoryId
    );

    if (get_product_details?.description !== undefined) {
      setaboutPage(get_product_details?.description);
    }

    setSelectedOption(selectCate);
    setproductData(get_product_details);

    // const proImg =
    //   get_product_details?.images &&
    //   get_product_details.images?.map((data) => {
    //     data.data_url = URL.API_BASE_URL + data.img;

    //     return data;
    //   });

    // setImagesproI(proImg);

    // setImages([
    //   { data_url: URL.API_BASE_URL + get_product_details.featureImage },
    // ]);
    setCroppedImageUrl(URL.API_BASE_URL + get_product_details.feature_image);

    // }
  }, [get_product_details]);

  //   const maxNumber = 1

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    // dispatch(adminAction.getProductList(1))
    // dispatch(allapiAction.getsubsubcate({}));
    dispatch(allapiAction.getAboutPageDetails({ id }));
    // dispatch(adminAction.getSellerListforpro({}))
    // dispatch(adminAction.searchtags({}))
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  }, [id]);

  useEffect(() => {
    const dropList = get_sub_sub_category_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [get_sub_sub_category_list]);

  const maxNumber = 3;

  //   const HendleImgFVer = (e, pictureDataURLs, index) => {

  //     setRows((prevRows) => {
  //       const updatedRows = [...prevRows];

  //       updatedRows[index].image(e[e?.length - 1]);
  //       return updatedRows;
  //     });
  //   };

  const HendleImgFVer = (e, pictureDataURLs, index) => {
    // Log to check if the function is getting the right parameters

    // Assuming that 'rows' is a state variable and 'setRows' is a state updater function
    setRows((prevRows) => {
      // Create a copy of the previous rows array to avoid mutating state directly
      const updatedRows = [...prevRows];

      // Assuming 'e' is an array of images, you want to push the last image to a specific row
      // You should check if 'updatedRows[index].image' is an array, and if not, initialize it as an array

      if (!Array.isArray(updatedRows[index].images)) {
        updatedRows[index].images = [];
      }

      // Push the last image from 'e' to the 'image' array in the specific row
      updatedRows[index].images(e[e.length - 1]);

      return updatedRows;
    });
  };
  const onRemoveImage = (variantIndex, imageIndex) => {
    setRows((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].images.splice(imageIndex, 1);
      return updatedVariants;
    });
  };

  const addSizeProduct = (index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].productsize({ size: "", price: "", stock: "" });

      return updatedRows;
    });
  };

  const removeSizeProduct = (rowIndex, sizeIndex) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].productsize.splice(sizeIndex, 1);
      return updatedRows;
    });
  };
  const removeColorProduct = (index) => {
    // setRows((prevRows) => {
    //   const updatedRows = [...prevRows]
    //   updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1)
    //   return updatedRows
    // })

    setRows((prevRows) => prevRows.filter((row, i) => i !== index));
  };

  const handleSizeChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].productsize[sizeIndex][field] = value;
      return updatedRows;
    });
  };
  const handleColorChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  //   createProduct2

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setproductData({ ...productData, [name]: value });
  };

  const hendleTosubmit = async () => {
    const data = new FormData();

    if (!productData?.title) {
      toast.error("please enter prodcat name");
    } /* else if (!selectedOption) {
      toast.error("please select category ");
    }  */ /* else if (!selectedOption1) {
          toast.error('please select seller')
        } */ else {
      data.append("_id", productData?._id);
      data.append("title", productData?.title);

      data.append("meta_description", productData?.meta_description);
      data.append("meta_title", productData?.meta_title);
      data.append("short_description", productData?.short_description);
      data.append("description", aboutPage);
      console.log("fileContant", fileContant);
      console.log("fileName", fileName);

      if (fileContant) {
        data.append("feature_image", fileContant, fileName);
      }

      //   if (images[0]?.file) {
      //     // roledata?.role_name
      //     data.append("featureImage", images[0]?.file);
      //   }
      //   data.append("featureImage", images[0]?.file);
      //   imagesproI?.forEach((file) => {
      //     if (file?.file) {
      //       data.append("images", file?.file);
      //     } else {
      //       data.append("oldimages", file?._id);
      //     }
      //   });
      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await Api.post(`${URL.updateAboutPages}`, data, config);

      navigate("/seller/about-page");
    }
  };
  return (
    <div className=" code-box">
      <div className="flex">
        <div>
          {/* <HeadingBack
            title={"Listing"}
            // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          /> */}
          <div className="flex gap10">
            <div>
              <Tooltip title="Back">
                <Link
                  to={"/seller/blog-list"}
                  //  onClick={() => navigate?.back()}
                >
                  {/* <LeftArrow
              className="scale-icon"
              style={{ verticalAlign: "middle" }}
            /> */}

                  <Image
                    src={SvgIcon?.LeftArrow}
                    alt="Left Arrow"
                    width={20}
                    height={20}
                    style={{ verticalAlign: "middle" }}
                  />
                  {/* <SvgIcon.LeftArrow
                    className="scale-icon"
                    style={{ verticalAlign: "middle" }}
                  /> */}
                </Link>
              </Tooltip>
            </div>
            <div>
              <div className="opacity-8 fw-bold fs-16">Edit Blog</div>
              {/* <div className="opacity-5 fw-600 fs-12">{subHeading}</div> */}
            </div>
          </div>
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
      </div>

      <div>
        <div className="container pdcon">
          <div className="row">
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Feature</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <div>
                  {/* <h1>Image Cropper</h1> */}
                  <ImageCropper
                    aspectRatio={4 / 3}
                    // onImageCrop={handleImageCrop}
                    setfileContant={setfileContant}
                    setFileName={setFileName}
                    setfinalImg={setfinalImg}
                    aspectwidth={16}
                    aspectheight={9}
                    onCropComplete={setCroppedImageUrl}
                  />
                  {croppedImageUrl && (
                    <div>
                      <img
                        src={croppedImageUrl}
                        alt="Cropped"
                        width={160}
                        height={100}
                      />
                    </div>
                  )}
                </div>
                {/* <ImageUploading
                  // multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                  // acceptType={['jpg']}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button>
                      &nbsp;
                       
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading> */}
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Blog Title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a blog title"
                  name="title"
                  value={productData?.title}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Short Description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a short description"
                  value={productData?.short_description}
                  name="short_description"
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>{" "}
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo title"
                  name="meta_title"
                  value={productData?.meta_title}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo description"
                  name="meta_description"
                  value={productData?.meta_description}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                {/* <CKEditor
                  editor={Editor}
                  data={aboutPage}
                  onReady={(editor) => {
                  
                  }}
                  onChange={(event, editor) => {
             
                    const data = editor.getData();
                    setaboutPage(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  onBlur={(event, editor) => {
                   
                  }}
                  onFocus={(event, editor) => {
                
                  }}
                /> */}
                <Cktest
                  aboutPage={aboutPage}
                  setaboutPage={setaboutPage}
                  uploadPlugin={uploadPlugin}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              {" "}
              <button
                className="btn btn-primary"
                onClick={() => hendleTosubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAboutPageComponent;
