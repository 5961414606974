import React from "react";

function PravicyPolicy() {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      <p>
        Our website, <a href="https://bestbharat.com">bestbharat.com</a>, is an
        e-commerce website, job portal, lost and found portal, and a multivendor
        platform where multivariation products are listed. It is our pleasure to
        provide you with the following details about our website.
      </p>
      <p>Our contact details are: 7691091000, Address: Jaipur, Rajsthan.</p>
      <p>
        We are committed to protecting your privacy and security. We do not
        collect any personal information unless it is absolutely necessary to
        process your order, provide you with a seamless shopping experience, or
        respond to your inquiries. We believe in transparency and here are the
        details of how we handle your personal information:
      </p>
      <ul>
        <li>
          When you place an order, we collect the personal information you
          provide during the checkout process.
        </li>
        <li>
          We use this information to process your order, send you order related
          communications, and protect your privacy.
        </li>
        <li>
          We do not share your personal information with third parties unless
          required by law.
        </li>
        <li>
          We use secure servers to protect your information from unauthorized
          access.
        </li>
        <li>
          We comply with all applicable laws and regulations when it comes to
          handling your personal information.
        </li>
      </ul>
      <p>
        We hope this information helps you understand how we handle your
        privacy. If you have any further questions, please don't hesitate to
        reach out to us.
      </p>
    </div>
  );
}

export default PravicyPolicy;
