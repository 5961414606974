// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";

function RecentLost({ lostdata }) {
  const get_home_all_pro = lostdata;
  const get_home_all_pro2 = useSelector((state) =>
    state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  );

  const recentrecentProduct = get_home_all_pro ? get_home_all_pro : [];
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.length == 1
        ? 1
        : get_home_all_pro?.length > 6
          ? 6
          : get_home_all_pro?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 4
                ? 4
                : get_home_all_pro?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 3
                : get_home_all_pro?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 3
                : get_home_all_pro?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div className="lostfound shewBox ps-deal-of-day">
        <div className="ps-container">
          {recentrecentProduct?.length > 0 && (
            <div className="ps-section__header">
              <div className="ps-block--countdown-deal">
                <div className="ps-block__left">
                  <h3>
                    <b>RECENT LOST AND FOUND</b>{" "}
                  </h3>
                </div>
                {/* <div className="ps-block__right">
                <figure>
                  <figcaption>End in:</figcaption>
                  <ul
                    className="ps-countdown"
                    data-time="December 30, 2021 15:37:25"
                  >
                    <li>
                      <span className="days"></span>
                    </li>
                    <li>
                      <span className="hours"></span>
                    </li>
                    <li>
                      <span className="minutes"></span>
                    </li>
                    <li>
                      <span className="seconds"></span>
                    </li>
                  </ul>
                </figure>
              </div> */}
              </div>
              {/* <a href="#">View all</a> */}
            </div>
          )}
          <div className="ps-section__content">
            {recentrecentProduct?.length > 0 && (
              <Slider {...settings}>
                {recentrecentProduct &&
                  recentrecentProduct?.map((data, i) => {
                    return (
                      <div className="recentlostfound" key={i}>
                        <div className="ps-product ps-product--inner p-4">
                          <div className="ps-product__thumbnail">
                            <div
                              className={
                                data?.subcategoryId == "found"
                                  ? "ps-product__badge hot"
                                  : "ps-product__badge"
                              }
                            >
                              {data?.subcategoryId}
                            </div>
                            <Link to={"/lost-and-found/" + data?.slug}>
                              {/* <img
                                src={URL?.API_BASE_URL + data?.image}
                                alt=""
                                height={"150px"}
                              /> */}
                              <CoustomImg
                                url={URL.API_BASE_URL + data?.image}
                                altkey={data?.title}
                                w1={"100%"}
                                w2={"100%"}
                                h1={80}
                                h2={130}
                              />
                            </Link>

                            {/* <ul className="ps-product__actions">
                            <li>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Read More"
                              >
                                <i className="icon-bag2"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                data-placement="top"
                                title="Quick View"
                                data-toggle="modal"
                                data-target="#product-quickview"
                              >
                                <i className="icon-eye"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add to Whishlist"
                              >
                                <i className="icon-heart"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Compare"
                              >
                                <i className="icon-chart-bars"></i>
                              </a>
                            </li>
                          </ul> */}
                          </div>
                          <div className="ps-product__container">
                            <div className="ps-product__content">
                              <Link
                                className="ps-product__title"
                                to={"/lost-and-found/" + data?.slug}
                              >
                                {/* {data?.name} */}

                                <TruncateText
                                  text={data?.name}
                                  maxLength={windowWidth > 600 ? 15 : 7}
                                />
                              </Link>

                              <div
                                className="ps-product__progress-bar ps-progress"
                                data-value="82"
                              >
                                {/* <p>
                                  24-09-2023
                                  <br />
                                  10.15AM
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">Found</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">Found</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">Found</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">Found</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">Found</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">Found</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge">Found</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recentlistor">
                <div className="ps-product">
                  <div className="ps-product__thumbnail">
                    <a href="product-default.html">
                      <img src="img/products/electronic/1.jpg" alt="" />
                    </a>
                    <div className="ps-product__badge  hot">Found</div>
                    <ul className="ps-product__actions">
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add To Cart"
                        >
                          <i className="icon-bag2"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-placement="top"
                          title="Quick View"
                          data-toggle="modal"
                          data-target="#product-quickview"
                        >
                          <i className="icon-eye"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add to Whishlist"
                        >
                          <i className="icon-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Compare"
                        >
                          <i className="icon-chart-bars"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ps-product__container">
                    <a className="ps-product__vendor" href="#">
                      Go Pro
                    </a>
                    <div className="ps-product__content">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <div className="ps-product__rating">
                        <select className="ps-rating" data-read-only="true">
                          <option value="1">1</option>
                          <option value="1">2</option>
                          <option value="1">3</option>
                          <option value="1">4</option>
                          <option value="2">5</option>
                        </select>
                        <span>01</span>
                      </div>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                    <div className="ps-product__content hover">
                      <a className="ps-product__title" href="product-default.html">
                        Marshall Kilburn Portable Wireless
                      </a>
                      <p className="ps-product__price sale">
                        $567.99 <del>$670.00 </del>
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              </Slider>
            )}

            {/* <div
              className="ps-carousel--nav owl-slider"
              data-owl-auto="false"
              data-owl-loop="false"
              data-owl-speed="10000"
              data-owl-gap="30"
              data-owl-nav="true"
              data-owl-dots="true"
              data-owl-item="7"
              data-owl-item-xs="2"
              data-owl-item-sm="3"
              data-owl-item-md="4"
              data-owl-item-lg="5"
              data-owl-item-xl="6"
              data-owl-duration="1000"
              data-owl-mousedrag="on"
            >
             
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentLost;
