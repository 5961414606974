// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// import CoustomImg from "../../../../Component/CoustomImg/CoustomImg";
// import SellerHeader from "../../../../Component/Layout/SellerHeader";
// import TruncateText from "../../../../Component/TruncateText/TruncateText";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
import CoustomImg from "../CoustomImg/CoustomImg";
import SellerHeader from "../Layout/SellerHeader";
import TruncateText from "../TruncateText/TruncateText";
import moment from "moment";
function VendorAbout({ post }) {
  const dispatch = useDispatch();
  const router = useNavigate();

  console.log(router);

  const { id } = useParams();
  const locationv = useLocation();
  const searchParams = new URLSearchParams(locationv.search);
  const search = searchParams.get("search");
  const location = searchParams.get("location");
  const page = searchParams.get("page");
  const blog_details = useSelector((state) =>
    state?.allapi?.blog_details ? state?.allapi?.blog_details : {}
  );
  const get_product_list = useSelector((state) =>
    state?.allapi?.user_product_search ? state?.allapi?.user_product_search : {}
  );
  const get_category_Filter = useSelector((state) =>
    state?.allapi?.get_category_Filter ? state?.allapi?.get_category_Filter : []
  );

  console.log(get_category_Filter);

  const pageno = page ? page : 1;

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];
  const listdataListing = get_product_list?.listingList?.docs
    ? get_product_list?.listingList?.docs
    : [];
  // get_product_list

  useEffect(() => {
    // searchProduct

    // if (search) {
    //   dispatch(
    //     allapiAction.userSearchProductuser({
    //       page: pageno,
    //       id: search,
    //       sellerid: id,
    //     })
    //   );
    // } else {
      dispatch(
        allapiAction.getAboutPageDetails2({ page: pageno, id: id })
      );
    // }
    // dispatch(allapiAction.getfiltercategory({}));
    return () => {};
  }, [locationv.search]);

  const henldetofilterbyCate = (e) => {
    if (search) {
      dispatch(
        allapiAction.userSearchProduct({ page: pageno, id: search, cateId: e })
      );
    }
  };
  const [childcateee, setchildcateee] = useState("");
  const hendletoviewchlidcate = (e) => {};

  const items = get_product_list?.totalDocs;
  const itemsPerPage = 18;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, get_product_list, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    // const newOffset = (event.selected * itemsPerPage) % items;
    // setItemOffset(newOffset);
    // setPage(data);
    // "/vendor-store/product/" + id + "?search=" + search + "&page=" + data

    const srch = search ? search : "";
    router("/vendor-store/product/" + id + "?search=" + srch + "&page=" + data);
    window.scrollTo(50, 0);
    // dispatch(allapiAction.lostfoundlistpublic({ page: data }));
  };
  return (
    <div>
      <SellerHeader vendorDetails={post} />

      <div className="container-fluid">
        <div className="ps-deal-of-day shewBox">
          <div className="ps-container">
            <div class="ps-layout--shop mt-30">

              <div class="">
                <div class="ps-block--shop-features">
                  {/* <div class="ps-block__header">
                    <h3>About</h3>
                    
                  </div> */}

                  {!blog_details?.title && (
                    <div className="notfound">
                      <h2>Not Details Found</h2>
                    </div>
                  )}
                  <div class="blog-one-area pt_60 pb_90">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="single-blog">
              <h3>{blog_details?.title}</h3>
                <img
                  src={URL?.API_BASE_URL + blog_details?.feature_image}
                  alt={blog_details?.title}
                />
                
               

                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: blog_details?.description,
                  }}
                ></div>
               
              </div>

           
 
            </div>

         
          </div>
        </div>
      </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
}

export default VendorAbout;
