// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useLayoutEffect, useState } from "react";
import Slider from "react-slick";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
import { Link, useNavigate } from "react-router-dom";
import { URL } from "../../Redux/common/url";
function Listors({ homedata }) {
  const get_home_all_pro = homedata;
  const route = useNavigate();

  const recentProduct = get_home_all_pro?.sellerlist
    ? get_home_all_pro?.sellerlist
    : [];

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.sellerlist?.length == 1
        ? 1
        : get_home_all_pro?.sellerlist?.length > 6
          ? 6
          : get_home_all_pro?.sellerlist?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.sellerlist?.length == 1
              ? 1
              : get_home_all_pro?.sellerlist?.length > 4
                ? 4
                : get_home_all_pro?.sellerlist?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.sellerlist?.length == 1
              ? 1
              : get_home_all_pro?.sellerlist?.length > 3
                ? 3
                : get_home_all_pro?.sellerlist?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.sellerlist?.length == 1
              ? 1
              : get_home_all_pro?.sellerlist?.length > 2
                ? 2
                : get_home_all_pro?.sellerlist?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div className="ps-product-list ps-clothings mt-5">
        <div className="ps-container ps-deal-of-day">
          <div className="ps-section__header">
            <div className="ps-block--countdown-deal">
              <div className="ps-block__left">
                <h3>
                  <b>SELLER</b>{" "}
                </h3>
              </div>
            </div>
            <Link to="/all-seller">View all</Link>
          </div>

          {/* <div className="container-fluid mt-2 mt-10 ">
            {recentProduct?.length > 0 && (
              <Slider {...settings}>
                {recentProduct &&
                  recentProduct?.map((data, i) => {
                    const imgdata = data?.sellerProfile
                      ? URL?.API_BASE_URL + data?.sellerProfile
                      : "/img/message/sudarshanchakra.gif";

                    const dname = data?.displayName
                      ? data?.displayName
                      : data?.fullName;
                    return (
                      <div
                        key={i}
                        className="recentlistor cusp"
                        onClick={() => {
                          route("/vendor-store/" + data?._id);
                        }}
                      >
                        <div className="card">
                          <div className="mb-1">
                            <CoustomImg
                              url={imgdata}
                              altkey={
                                data?.displayName
                                  ? data?.displayName
                                  : data?.fullName
                              }
                              w1={165}
                              w2={300}
                              h1={150}
                              h2={250}
                            />
                            <div className="d-flex justify-content-around icon_lf">
                              <Link to={"/vendor-store/" + data?._id}>
                                <button className="badge badge-warning like">
                                  <i>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24"
                                      height="24"
                                    >
                                      <path
                                        d="M2 8.99997H5V21H2C1.44772 21 1 20.5523 1 20V9.99997C1 9.44769 1.44772 8.99997 2 8.99997ZM7.29289 7.70708L13.6934 1.30661C13.8693 1.13066 14.1479 1.11087 14.3469 1.26016L15.1995 1.8996C15.6842 2.26312 15.9026 2.88253 15.7531 3.46966L14.5998 7.99997H21C22.1046 7.99997 23 8.8954 23 9.99997V12.1043C23 12.3656 22.9488 12.6243 22.8494 12.8658L19.755 20.3807C19.6007 20.7554 19.2355 21 18.8303 21H8C7.44772 21 7 20.5523 7 20V8.41419C7 8.14897 7.10536 7.89462 7.29289 7.70708Z"
                                        fill="rgba(21,117,211,1)"
                                      ></path>
                                    </svg>
                                  </i>
                                </button>
                              </Link>
                              <Link to={"/vendor-store/" + data?._id}>
                                <button className="profile badge badge-warning">
                                  Follow
                                </button>
                              </Link>
                            </div>
                          </div>
                          <h3 className="profile-name">
                            <TruncateText text={dname} maxLength={12} />
                           
                          </h3>
                          
                          <small>
                            <span>
                              <i>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="18"
                                  height="18"
                                >
                                  <path
                                    d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"
                                    fill="rgba(240,187,64,1)"
                                  ></path>
                                </svg>
                              </i>
                            </span>
                            {data?.district},{data?.state}
                          </small>
                          <small className="mb-3">
                            <span>
                              <i>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="16"
                                  height="16"
                                >
                                  <path
                                    d="M21 16.42V19.9561C21 20.4811 20.5941 20.9167 20.0705 20.9537C19.6331 20.9846 19.2763 21 19 21C10.1634 21 3 13.8366 3 5C3 4.72371 3.01545 4.36687 3.04635 3.9295C3.08337 3.40588 3.51894 3 4.04386 3H7.5801C7.83678 3 8.05176 3.19442 8.07753 3.4498C8.10067 3.67907 8.12218 3.86314 8.14207 4.00202C8.34435 5.41472 8.75753 6.75936 9.3487 8.00303C9.44359 8.20265 9.38171 8.44159 9.20185 8.57006L7.04355 10.1118C8.35752 13.1811 10.8189 15.6425 13.8882 16.9565L15.4271 14.8019C15.5572 14.6199 15.799 14.5573 16.001 14.6532C17.2446 15.2439 18.5891 15.6566 20.0016 15.8584C20.1396 15.8782 20.3225 15.8995 20.5502 15.9225C20.8056 15.9483 21 16.1633 21 16.42Z"
                                    fill="rgba(240,187,64,1)"
                                  ></path>
                                </svg>
                              </i>
                            </span>
                            {data?.phone}
                          </small>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div> */}
          <div className="  mt-2 mt-10 ">
            {recentProduct?.length > 0 && (
              <Slider {...settings}>
                {recentProduct &&
                  recentProduct?.map((data, i) => {
                    const imgdata = data?.sellerProfile
                      ? URL?.API_BASE_URL + data?.sellerProfile
                      : "/img/message/sudarshanchakra.gif";

                    const dname = data?.displayName
                      ? data?.displayName
                      : data?.fullName;
                    return (
                      <div key={i} className="recentlistor cusp">
                        {/* animated_card  verifyd */}
                        {/* blankcard */}
                        <div class="card animated_card">
                          <img
                            src={imgdata}
                            class="my_card_image"
                            onClick={() => {
                              route("/vendor-store/" + data?._id);
                            }}
                          ></img>
                          <div class="my_contant p-4">
                            <h4 class="profile-name">
                            <Link to={"/vendor-store/" + data?._id}>
                              <TruncateText
                                text={dname}
                                // maxLength={12}
                                maxLength={windowWidth > 600 ? 22 : 11}
                                // onClick={() => {
                                //   route("/vendor-store/" + data?._id);
                                // }}
                              />
                              </Link>
                            </h4>
                            <h5>
                              <span>
                                <i>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                  >
                                    <path
                                      d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"
                                      fill="rgba(240,187,64,1)"
                                    ></path>
                                  </svg>
                                </i>
                              </span>
                              &nbsp; {data?.district},{data?.state}
                            </h5>
                            <h5>
                              <span>
                                <i>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="16"
                                    height="16"
                                  >
                                    <path
                                      d="M21 16.42V19.9561C21 20.4811 20.5941 20.9167 20.0705 20.9537C19.6331 20.9846 19.2763 21 19 21C10.1634 21 3 13.8366 3 5C3 4.72371 3.01545 4.36687 3.04635 3.9295C3.08337 3.40588 3.51894 3 4.04386 3H7.5801C7.83678 3 8.05176 3.19442 8.07753 3.4498C8.10067 3.67907 8.12218 3.86314 8.14207 4.00202C8.34435 5.41472 8.75753 6.75936 9.3487 8.00303C9.44359 8.20265 9.38171 8.44159 9.20185 8.57006L7.04355 10.1118C8.35752 13.1811 10.8189 15.6425 13.8882 16.9565L15.4271 14.8019C15.5572 14.6199 15.799 14.5573 16.001 14.6532C17.2446 15.2439 18.5891 15.6566 20.0016 15.8584C20.1396 15.8782 20.3225 15.8995 20.5502 15.9225C20.8056 15.9483 21 16.1633 21 16.42Z"
                                      fill="rgba(240,187,64,1)"
                                    ></path>
                                  </svg>
                                </i>
                              </span>
                              &nbsp;&nbsp; {data?.phone}
                            </h5>
                          </div>

                          <div class="seal">
                            <img src="/special.png"></img>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>

          {/* <div className="container-fluid mt-10">
            <div class="row">
              <div class="col-md-4 col-6 p-3">
                <div class="card animated_card">
                  <img
                    src="https://th.bing.com/th/id/OIP.dcLtZnQtLx1m4ZZQNSlOnQHaH3?rs=1&pid=ImgDetMain"
                    class="my_card_image"
                  ></img>
                  <div class="my_contant p-4">
                    <h4 class="profile-name">Dev drbar</h4>
                    <h5>
                      <span>
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path
                              d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"
                              fill="rgba(240,187,64,1)"
                            ></path>
                          </svg>
                        </i>
                      </span>
                      &nbsp; Murlipura, RAJASTHAN, India,302039
                    </h5>
                    <h5>
                      <span>
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                          >
                            <path
                              d="M21 16.42V19.9561C21 20.4811 20.5941 20.9167 20.0705 20.9537C19.6331 20.9846 19.2763 21 19 21C10.1634 21 3 13.8366 3 5C3 4.72371 3.01545 4.36687 3.04635 3.9295C3.08337 3.40588 3.51894 3 4.04386 3H7.5801C7.83678 3 8.05176 3.19442 8.07753 3.4498C8.10067 3.67907 8.12218 3.86314 8.14207 4.00202C8.34435 5.41472 8.75753 6.75936 9.3487 8.00303C9.44359 8.20265 9.38171 8.44159 9.20185 8.57006L7.04355 10.1118C8.35752 13.1811 10.8189 15.6425 13.8882 16.9565L15.4271 14.8019C15.5572 14.6199 15.799 14.5573 16.001 14.6532C17.2446 15.2439 18.5891 15.6566 20.0016 15.8584C20.1396 15.8782 20.3225 15.8995 20.5502 15.9225C20.8056 15.9483 21 16.1633 21 16.42Z"
                              fill="rgba(240,187,64,1)"
                            ></path>
                          </svg>
                        </i>
                      </span>
                      &nbsp;&nbsp; 1234567890
                    </h5>
                  </div>

                  <div class="seal">
                    <img src="https://www.conexdepot.com/wp-content/uploads/2022/09/%E2%80%94Pngtree%E2%80%94trust-seller-badge-checkmark_7885139-400x400.png"></img>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-6 p-3">
                <div class="card animated_card">
                  <div class="seal">
                    <img src="https://www.conexdepot.com/wp-content/uploads/2022/09/%E2%80%94Pngtree%E2%80%94trust-seller-badge-checkmark_7885139-400x400.png"></img>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-6 p-3">
                <div class="card blankcard">
                  <div class="seal">
                    <img src="https://www.conexdepot.com/wp-content/uploads/2022/09/%E2%80%94Pngtree%E2%80%94trust-seller-badge-checkmark_7885139-400x400.png"></img>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Listors;
