import React, { useEffect, useState } from "react";
// import dynamic from "next/dynamic";
// import { URL } from "@/Redux/common/url";
// const Cktest = dynamic(() => import("../cktetst/Cktest"), {
//   ssr: false,
// });

import ImageUploading from "react-images-uploading";
// import { useRouter } from "next/router";
import { toast } from "react-toastify";
// import Api from "@/Redux/common/api";

import { useNavigate } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import Api from "../../Redux/common/api";
import Cktest from "../cktetst/Cktest";
import { AsyncPaginate } from "react-select-async-paginate";
import HomeSlider2 from "../Home/HomeSlider2";
import { useDispatch } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import OurWork from "../OurWork/OurWork";

function AddLost() {
  const [lostfinddata, setlostfinddata] = useState({
    subCate: "",
    subsubCate: "",
    country: "India",
  });
  const [state, setState] = useState("");
  const [CitysList, setCitysList] = useState([]);

  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [valuesetUserDeatils, setUserDeatils] = useState({});
  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));

      const userDetails = localStorage.getItem("access_user");

      setUserDeatils(JSON?.parse(userDetails));
    } else {
      setValue("");
      setValuevendor("");
      setUserDeatils({});
    }
  }, []);
  // const allStates = State.getAllStates();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  // const statesindia =
  //   allStates &&
  //   allStates?.filter((data) => {
  //     return data.countryCode == "IN";
  //   });

  // console.log(allCity);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allapiAction.getsliderList({ page: "addlostandfound" }));
  }, []);
  // useEffect(() => {
  //   if (state) {
  //     const statecode = statesindia.find((data) => data.name == state);
  //     const cityData = City.getCitiesOfState("IN", statecode?.isoCode);
  //     setCitysList(cityData);
  //   }
  // }, [state]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const hendletochange = (e) => {
    const { name, value } = e?.target;
    setlostfinddata({ ...lostfinddata, [name]: value });
  };

  const [aboutPage, setaboutPage] = useState("");
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const [stateselect, setstateselect] = useState(null);
  const [districtselect, setdistrictselect] = useState(null);
  console.log(stateselect);
  const [cityselect, setcityselect] = useState(null);

  const [mangeKey, setmangeKey] = useState(0);
  const [mangeKey2, setmangeKey2] = useState(0);

  useEffect(() => {
    setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setdistrictselect(null);
    setcityselect(null);

    return () => {};
  }, [stateselect]);
  useEffect(() => {
    // setmangeKey(mangeKey + 1);
    setmangeKey2(mangeKey2 + 1);
    setcityselect(null);
    return () => {};
  }, [districtselect]);

  const loadOptionsState = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaStates?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsDisrcit = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaDistricts?key=${search}&page=${pages?.page}&States_id=${stateselect?.State}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionsCity = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacity?key=${search}&page=${pages?.page}&District_id=${districtselect?.District}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.Area + ", " + data?.Pincode;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const maxNumber = 1;
  const hendleTosubmit = async () => {
    const data = new FormData();
    try {
      if (images?.length == 0) {
        toast.error("please select  Image");
      } else if (!lostfinddata?.name) {
        toast.error("please enter  name");
      }/*  else if (!lostfinddata?.phone1) {
        toast.error("please enter phone ");
      }  */else if (!lostfinddata?.subCate) {
        toast.error("please select category ");
      } else if (!lostfinddata?.subsubCate) {
        toast.error("please select sub category ");
      } else if (!stateselect?.State) {
        toast.error("please select state ");
      } else if (!districtselect?.District) {
        toast.error("please select district ");
      } else if (!cityselect?.Area) {
        toast.error("please select city ");
      } else if (!lostfinddata?.address) {
        toast.error("please enter address ");
      } else {
        // if (condition) {

        // }
        data.append("name", lostfinddata?.name);
        data.append("categoryId", "lostandfind");
        data.append("subcategoryId", lostfinddata?.subCate);
        data.append("subsubcategoryId", lostfinddata?.subsubCate);

        if (lostfinddata?.color) {
          data.append("color", lostfinddata?.color);
        }
        if (lostfinddata?.gender) {
          data.append("gender", lostfinddata?.gender);
        }
        if (lostfinddata?.company) {
          data.append("company", lostfinddata?.company);
        }
        if (lostfinddata?.marks) {
          data.append("marks", lostfinddata?.marks);
        }

        if (lostfinddata?.age) {
          data.append("age", lostfinddata?.age);
        }
        if (lostfinddata?.relation) {
          data.append("relation", lostfinddata?.relation);
        }
        if (lostfinddata?.reword) {
          data.append("reword", lostfinddata?.reword);
        }

        data.append("pincode", cityselect?.Pincode);
        data.append("city", cityselect?.Area);
        data.append("location_id", cityselect?._id);
        data.append("state", stateselect?.State);
        data.append("district", districtselect?.District);

        data.append("country", lostfinddata?.country);
        data.append("address", lostfinddata?.address);
        data.append("phone1", valuesetUserDeatils?.phone);
        data.append("phone2", lostfinddata?.phone2);
        data.append("description", aboutPage);
        data.append("image", images[0]?.file);

        const token = localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        if (localStorage.getItem("access_token")) {
          const response = await Api.post(
            `${URL.lostfoundsubmit}`,
            data,
            config
          );

          if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate("/");
          } else {
            toast.error(response?.data?.message);
          }
        } else {
          const token = localStorage.getItem("access_token_vendor");
          const config = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };
          const response = await Api.post(
            `${URL.lostfoundsubmitseller}`,
            data,
            config
          );

          if (response?.data?.success) {
            toast.success(response?.data?.message);
            navigate("/");
          } else {
            toast.error(response?.data?.message);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "50px", // Set your desired height here
      minHeight: "50px",
      borderColor: state.isFocused ? "blue" : "grey", // Change border color on focus
      boxShadow: state.isFocused ? "0 0 0 1px blue" : null,
      "&:hover": {
        borderColor: state.isFocused ? "blue" : "grey",
      },
    }),
    input: (provided) => ({
      ...provided,
      height: "50px", // Ensure the input height matches the control height
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "50px", // Ensure the value container height matches the control height
      display: "flex",
      alignItems: "center",
      padding: "0 6px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "50px", // Ensure the indicators container height matches the control height
    }),
  };
  return (
    <div className="mt-20">
      <div className="hometest">
        <HomeSlider2 />
      </div>
      <h2 className="text-center  animated_text ">LOST AND FOUND PORTOAL</h2>
      <div className="container mt-25 lost_fount_form p-20" id="box">
        <div className="form-row">
          <div className="form-group col-md-4 col-sm-4">
            <label for="exampleFormControlSelect1">
              Select Found or lost *
            </label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              name="subCate"
              onChange={(e) => {
                hendletochange(e);
              }}
            >
              <option value={""}>Select</option>
              <option class="found-option" value={"found"}>
                Found(पाया )
              </option>
              <option class="lost-option" value={"lost"}>
                Lost(खोया)
              </option>
            </select>
          </div>

          {lostfinddata?.subCate && (
            <div className="form-group col-md-4 col-sm-4">
              <label for="exampleFormControlSelect1">
                Select Member | Pet- Animal | Product- Document - other
              </label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                name="subsubCate"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                <option value={""}>select</option>
                <option value={"Family Member"}>Member(सदस्य)</option>
                <option value={"Pet-animal"}>Pet-animal(पालतू जानवर)</option>
                <option value={"Product-Document-other"}>
                  Product-Document-other(सामान -दस्तावेज-अन्य)
                </option>
              </select>
            </div>
          )}
        </div>
        <div className="form-row mt5">
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputEmail4">Lost| Found name (Title) शीर्षक</label>
            <input
              type="text"
              className="form-control"
              id="inputEmail4"
              name="name"
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </div>
          {lostfinddata?.subsubCate !== "Product-Document-other" &&
            lostfinddata?.subsubCate && (
              <div className="form-group col-md-3 col-sm-3">
                <label for="inputPassword4">Gender</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  name="gender"
                  onChange={(e) => {
                    hendletochange(e);
                  }}
                >
                  <option value={""}>select</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                  <option value={"other"}>Other</option>
                </select>
              </div>
            )}
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputEmail4">Phone Number-1 फोन नंबर-1</label>
            <input
              type="text"
              className="form-control"
              id="inputEmail4"
              name="phone1"
              value={valuesetUserDeatils?.phone}
              // onChange={(e) => {
              //   hendletochange(e);
              // }}
            />
          </div>
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputPassword4">Phone Number-2 फोन नंबर-2</label>
            <input
              type="text"
              className="form-control"
              id="inputPassword4"
              name="phone2"
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </div>
        </div>
        <div className="form-row mt-5">
          {/* <div className="form-group col-md-3 col-sm-3">
            <label for="inputCity">Country</label>
            <input
              type="text"
              className="form-control"
              id="inputcountry"
              name="country"
              value={"India"}
            />
          </div> */}
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputState">State</label>

            <AsyncPaginate
              value={stateselect}
              className="liceninput2"
              placeholder="State"
              loadOptions={loadOptionsState}
              onChange={setstateselect}
              style={customStyles}
              additional={{
                page: 1,
              }}
            />
            {/* <select
              id="inputState"
              className="form-control shadow-lg bg-white"
              onChange={(e) => {
                setState(e?.target?.value);
                hendletochange(e);
              }}
              name="state"
            >
              <option value={""}>Select State</option>

              {statesindia &&
                statesindia?.map((data, i) => {
                  return (
                    <option key={i} value={data?.name}>
                      {data?.name}
                    </option>
                  );
                })}
            </select> */}
          </div>
          {stateselect && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="inputCity">District</label>

              <AsyncPaginate
                key={mangeKey}
                value={districtselect}
                className="liceninput2"
                placeholder="District"
                loadOptions={loadOptionsDisrcit}
                onChange={setdistrictselect}
                additional={{
                  page: 1,
                }}
              />

              {/* <select
                id="inputState"
                className="form-control shadow-lg bg-white"
                onChange={(e) => {
                  hendletochange(e);
                }}
                name="city"
              >
                <option value={""}>Select city</option>

                {CitysList &&
                  CitysList?.map((data, i) => {
                    return (
                      <option key={i} value={data?.name}>
                        {data?.name}
                      </option>
                    );
                  })}
              </select> */}
            </div>
          )}
          {districtselect && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="inputCity">City</label>

              <AsyncPaginate
                key={mangeKey2}
                value={cityselect}
                className="liceninput2"
                placeholder="City"
                loadOptions={loadOptionsCity}
                onChange={setcityselect}
                additional={{
                  page: 1,
                }}
              />

              {/* <select
                id="inputState"
                className="form-control shadow-lg bg-white"
                onChange={(e) => {
                  hendletochange(e);
                }}
                name="city"
              >
                <option value={""}>Select city</option>

                {CitysList &&
                  CitysList?.map((data, i) => {
                    return (
                      <option key={i} value={data?.name}>
                        {data?.name}
                      </option>
                    );
                  })}
              </select> */}
            </div>
          )}
          {/* <div className="form-group col-md-3 col-sm-3">
            <label for="inputZip">Zip</label>
            <input
              type="text"
              className="form-control"
              id="inputZip"
              name="pincode"
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </div> */}
        </div>
        <div className="form-row mt-5">
          <div className="form-group col-md-4 col-sm-4">
            <label className="form-label mb-7">
              <span className="required">Image</span>
              <i
                className="fa fa-exclamation-circle"
                data-bs-toggle="popover"
                data-bs-trigger="hover"
                data-bs-html="true"
                data-bs-content="Permission names is required to be unique."
              ></i>
            </label>
            <ImageUploading
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              acceptType={["png", "jpeg", "jpg", "webp"]}
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div className="upload__image-wrapper">
                  <button
                    style={isDragging ? { color: "red" } : {}}
                    onClick={onImageUpload}
                    {...dragProps}
                    className="btn btn-secondary w-100"
                  >
                    Choose feature image
                  </button>
                  &nbsp;
                  {imageList?.map((image, index) => (
                    <div key={index} className="image-item">
                      <img src={image.data_url} alt="" width="100" />
                      <div className="image-item__btn-wrapper">
                        <button
                          className="btn btn-secondary"
                          onClick={() => onImageUpdate(index)}
                        >
                          Update
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-secondary"
                          onClick={() => onImageRemove(index)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>
          <div className="form-group col-md-8 col-sm-8">
            <label for="inputAddress">
              Address of Lost & Found (खोया और पाया का पता)
            </label>
            <input
              type="text"
              className="form-control"
              id="inputAddress"
              // placeholder="Address of Lost & Found खोया और पाया का पता"
              name="address"
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </div>
        </div>
        <div className="form-row mt5">
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputEmail4">Color रंग</label>
            <input
              type="text"
              className="form-control"
              id="inputEmail4"
              name="color"
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </div>
          {lostfinddata?.subCate == "lost" &&
            lostfinddata?.subsubCate == "Family Member" && (
              <div className="form-group col-md-3 col-sm-3">
                <label for="inputPassword4">Relation रिश्ता</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  name="relation"
                  onChange={(e) => {
                    hendletochange(e);
                  }}
                >
                  <option>Select</option>
                  <option value={"male"}>Brother</option>
                  <option value={"female"}>Sister</option>
                  <option value={"father"}>Father</option>
                  <option value={"mother"}>Mother</option>
                  <option value={"grandfather"}>Grand Father</option>
                  <option value={"garndmother"}>Grand Mother</option>
                  <option value={"husband"}>Husband</option>
                  <option value={"wife"}>Wife</option>
                  <option value={"friend"}>Friend</option>
                  <option value={"daughter"}>Daughter</option>
                  <option value={"son"}>Son</option>
                  <option value={"other"}>other</option>
                </select>
              </div>
            )}
          {lostfinddata?.subsubCate !== "Product-Document-other" && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="inputEmail4">Age आयु</label>
              <input
                type="text"
                className="form-control"
                id="inputEmail4"
                name="age"
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          )}
          {lostfinddata?.subsubCate !== "Product-Document-other" && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="inputPassword4">
                Any body mark कोई भी शरीर का निशान
              </label>
              <input
                type="text"
                className="form-control"
                id="inputPassword4"
                name="marks"
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          )}
          {lostfinddata?.subsubCate == "Product-Document-other" && (
            <div className="form-group col-md-3 col-sm-3">
              <label for="inputPassword4">Company</label>
              <input
                type="text"
                className="form-control"
                id="inputPassword4"
                name="company"
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          )}
        </div>
        <Cktest
          aboutPage={aboutPage}
          setaboutPage={setaboutPage}
          uploadPlugin={uploadPlugin}
        />
        <div className="form-row mt-5">
          <div className="form-group col-md-3 col-sm-3">
            <label for="inputPassword4">Reward</label>
            <input
              type="number"
              className="form-control"
              id="inputPassword4"
              name="reword"
              onChange={(e) => {
                hendletochange(e);
              }}
            />
          </div>
        </div>
        <button
          className="mt-3 btn btn-primary btn-md"
          onClick={() => {
            hendleTosubmit();
          }}
        >
          SAVE AND CONTINUE
        </button>
      </div>
      <div className="mt-15">
        <OurWork />
      </div>
    </div>
  );
}

export default AddLost;
