import React from "react";

function TremsAndCondition() {
  return (
    <div className="container">
      <h2>Terms and Conditions </h2>
      <p>
        Welcome to BestBharat.com, an e-commerce portal and a lost and found
        portal and job portal.
      </p>
      <p>
        By using this website, you agree to comply with and be bound by the
        following terms and conditions of use. If you do not agree to these
        terms, you should not use this website.
      </p>
      <p>
        The use of this website is governed by the laws of India. You agree to
        submit to the exclusive jurisdiction of the courts of India.
      </p>
      <p>
        We reserve the right to make changes to these terms and conditions at
        any time. If you have any questions about these terms and conditions,
        please contact us at <a href="tel:7691091000">7691091000</a>.
      </p>
    </div>
  );
}

export default TremsAndCondition;
